import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

import Slant from '../Slant';

import './AnalysisBenefitsSection.scss';

const weatherIcon = require('../../assets/img/frogo-icon-analyse-weather.svg');
const freeIcon = require('../../assets/img/frogo-icon-analyse-free.svg');
const knowledgeIcon = require('../../assets/img/frogo-icon-analyse-knowledge.svg');
const diagramIcon = require('../../assets/img/frogo-icon-analyse-diagram.svg');

const AnalysisBenefitsSection = () => {
  return (
    <section className="AnalysisBenefitsSection text-white">
      <Container>
        <Row>
          <Col xs={12}>
            <h5 className="text-uppercase text-center AnalysisBenefitsSection-headline">
              Ihre Vorteile
            </h5>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col xs={12} md={6} className="pt-4 pr-lg-4">
            <Row>
              <Col xs={12} lg={3} className="pr-lg-0 text-center">
                <img
                  src={weatherIcon}
                  alt="Weather-Icon"
                  className="AnalysisBenefitsSection-icon"
                />
              </Col>
              <Col xs={12} lg={9} className="pl-lg-0">
                <h2 className="AnalysisBenefitsSection-content-headline">
                  Umgebungsfaktoren <br />
                  identifizieren
                </h2>
                <p className="lead AnalysisBenefitsSection-content">
                  Finden Sie heraus, welche Umgebungsfaktoren Ihre Kampagnen
                  beeinflussen (z.B. Wetter)
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} className="pt-4 pl-lg-4">
            <Row>
              <Col xs={12} lg={3} className="text-center">
                <img
                  src={diagramIcon}
                  alt="Diagram-Icon"
                  className="AnalysisBenefitsSection-icon"
                />
              </Col>
              <Col xs={12} lg={9} className="pl-lg-0">
                <h2 className="AnalysisBenefitsSection-content-headline">
                  Vorsprung durch Wissen
                </h2>
                <p className="lead AnalysisBenefitsSection-content">
                  Nutzen Sie das neu gewonnene Wissen, um Ihre Conversion-Rate
                  zu verbessern und Ihre Online-Marketing-Strategie zu justieren
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-sm-5">
          <Col xs={12} md={6} className="pt-4 pr-lg-4">
            <Row>
              <Col xs={12} lg={3} className="text-center">
                <img
                  src={knowledgeIcon}
                  alt="Knowledge-Icon"
                  className="AnalysisBenefitsSection-icon"
                />
              </Col>
              <Col xs={12} lg={9} className="pl-lg-0">
                <h2 className="AnalysisBenefitsSection-content-headline">
                  Fakten schaffen
                </h2>
                <p className="lead AnalysisBenefitsSection-content">
                  Mit der Datenanalyse von FROGO AD können neue Fakten
                  geschaffen und Bauchgefühle verifiziert werden
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} className="pt-4 pl-lg-4">
            <Row>
              <Col xs={12} lg={3} className="text-center">
                <img
                  src={freeIcon}
                  alt="Free-of-charge-Icon"
                  className="AnalysisBenefitsSection-icon"
                />
              </Col>
              <Col xs={12} lg={9} className="pl-lg-0">
                <h2 className="AnalysisBenefitsSection-content-headline">
                  Gratis testen
                </h2>
                <p className="lead AnalysisBenefitsSection-content">
                  Für die <b>zwei</b> klick-stärksten Kampagnen jedes
                  Adwords-Accounts ist die Analyse kostenlos und unverbindlich
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Slant style={{ color: '#ecf2f6' }} />
    </section>
  );
};

export default AnalysisBenefitsSection;
