import * as React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../components/Layout';
import SwappingNav from '../../components/SwappingNav';
import TopNavbar from '../../components/TopNavBar';
import KeyVisualAnalyseSection from '../../components/Analyse/KeyVisualAnalyseSection';
import AnalysisIntroductionSection from '../../components/Analyse/AnalysisIntroductionSection';
import AnalysisBenefitsSection from '../../components/Analyse/AnalysisBenefitsSection';
import CooperationStepsSection from '../../components/Analyse/CooperationStepsSection';

import Footer from '../../components/Footer';
import IeWarning from '../../components/IeWarning';

const metaKeywords =
  'frogo ad, frogoad, data driven marketing, wetter, performance, adwords optimierung, google adwords, google ads,' +
  'analyse, datenanalyse, datengetrieben, analyse, umgebung, kontext, kampagne, conversion, predictive analytics';

const metaDescription =
  'Die Datenanalyse von FROGO AD identifiziert Umgebungsfaktoren wie z.B. Wetter, Regionen oder Zeit, ' +
  'welche die Kaufwahrscheinlichkeit Ihrer Zielgruppe beeinflussen. Mithilfe von ‘Predictive Analytics’ ' +
  'werden Ihre historischen Performancedaten mit 22 unterschiedlichen Umgebungsfaktoren korreliert.';

export default class extends React.Component<{}, {}> {
  public render() {
    return (
      <Layout>
        <Helmet>
          <title>Google Ads Datenanalyse</title>
          <meta name="keywords" content={metaKeywords} />
          <meta name="description" content={metaDescription} />
        </Helmet>
        <SwappingNav
          scrollInHeight={15}
          initialNavbar={<TopNavbar transparent={true} />}
          swappedNavbar={<TopNavbar />}
        />
        <main>
          <KeyVisualAnalyseSection />
          <AnalysisIntroductionSection />
          <AnalysisBenefitsSection />
          <CooperationStepsSection />
          <Footer />
        </main>
        <IeWarning />
      </Layout>
    );
  }
}
