import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

import Slant from '../Slant';
import CheckMarkIcon from '../CheckMarkIcon';

import './KeyVisualAnalyseSection.scss';

const analysisIcon = require('../../assets/img/frogo-icon-analyse-keyvisual.svg');

const KeyVisualAnalyseSection = () => {
  return (
    <section className="KeyVisualAnalyseSection text-white">
      <Container>
        <Row className="pt-2">
          <Col xs={12} md={6}>
            <p className="lead text-left text-md-right">
              <img
                src={analysisIcon}
                alt="Analyse-Icon"
                className="img-fluid KeyVisualAnalyseSection-icon-analysis"
              />
            </p>
          </Col>
          <Col xs={12} md={6} className="pt-4">
            <h2 className="mb-0">Google Ads Datenanalyse</h2>
            <p className="lead KeyVisualAnalyseSection-text">
              Analyse zur Optimierung Ihrer Google&nbsp;Ads
            </p>
            <ul className="KeyVisualAnalyseSection-sub-text KeyVisualAnalyseSection-icon-list">
              <li>
                <CheckMarkIcon className="pr-1" height="1em" />
                Umgebungsfaktoren identifizieren
              </li>
              <li>
                <CheckMarkIcon className="pr-1" height="1em" />
                Optimierungspotenzial erkennen
              </li>
              <li>
                <CheckMarkIcon className="pr-1" height="1em" />
                Fakten schaffen
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Slant style={{ color: '#ecf2f6' }} />
    </section>
  );
};

export default KeyVisualAnalyseSection;
