import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

import './AnalysisIntroductionSection.scss';

const analyseImageWidescreen = require('../../assets/img/frogo-example-analyse-widescreen.svg');
const analyseImageSmartphone = require('../../assets/img/frogo-example-analyse-smartphone.svg');

const AnalysisIntroductionSection = () => {
  return (
    <section className="AnalysisIntroductionSection">
      <Container className="AnalysisIntroductionSection-content">
        <Row>
          <Col xs={12}>
            <h5 className="text-uppercase text-center AnalysisIntroductionSection-headline">
              Wozu eine Datenanalyse?
            </h5>
          </Col>
        </Row>
        <Row className="pt-2">
          <Col xs={12} sm={6}>
            <p className="lead text-left text-sm-right AnalysisIntroductionSection-content">
              Die <b>Datenanalyse</b> von FROGO AD identifiziert
              Umgebungsfaktoren wie z.B. Wetter, Regionen oder Zeit, welche die{' '}
              <b>Kaufwahrscheinlichkeit</b> Ihrer Zielgruppe beeinflussen.
              Mithilfe von <b>'Predictive Analytics'</b> werden Ihre
              historischen Performancedaten mit 22 unterschiedlichen
              Umgebungsfaktoren korreliert.
            </p>
          </Col>
          <Col xs={12} sm={6}>
            <p className="lead text-left text-sm-left AnalysisIntroductionSection-content">
              Auf diese Weise kann die FROGO AD Datenanalyse verborgene{' '}
              <b>Optimierungs- und Einsparpotenziale</b> Ihrer Google Ads
              Kampagnen aufdecken. Das Erkennen und Qualifizieren solcher
              Optimierungspotenziale ist der erste Schritt zur{' '}
              <b>datengetriebenen</b> und <b>kontextabhängigen Optimierung</b>{' '}
              Ihrer Kampagnen.
            </p>
          </Col>
        </Row>
        <Row className="pt-2">
          <Col xs={12} className="text-center py-4">
            <picture>
              <source
                srcSet={analyseImageWidescreen}
                type="image/svg+xml"
                media="(min-width: 1024px)"
                className="img-fluid AnalysisIntroductionSection-analyse-image"
              />
              <source
                srcSet={analyseImageSmartphone}
                type="image/svg+xml"
                className="img-fluid AnalysisIntroductionSection-analyse-image"
              />
              <img
                src={analyseImageWidescreen}
                className="img-fluid AnalysisIntroductionSection-analyse-image"
                alt="Umgebungsfaktorenen Analyse"
              />
            </picture>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AnalysisIntroductionSection;
