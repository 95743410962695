import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withPrefix } from 'gatsby';

import './CooperationStepsSection.scss';

const CooperationStepsSection = () => {
  return (
    <section className="CooperationStepsSection">
      <Container>
        <Row>
          <Col xs={12} className="text-center">
            <h5 className="CooperationStepsSection-headline text-uppercase">
              Wie läuft die Datenanalyse ab?
            </h5>
            <div className="pt-2 lead text-sm-left CooperationStepsSection-content">
              <h6 className="CooperationStepsSection-content-headline">
                1. Zugriffsanfrage gewähren
              </h6>
              <p>
                Nachdem Sie eine Anfrage zur Datenanalyse gestellt haben,
                erhalten Sie zunächst eine Zugriffsanfrage von FROGO AD auf Ihr
                Google Ads Konto.
              </p>
            </div>
            <div className="pt-2 lead text-sm-left CooperationStepsSection-content">
              <h6 className="CooperationStepsSection-content-headline">
                2. FROGO AD führt Analyse durch
              </h6>
              <p>
                Sobald Sie die Zugriffsanfrage bestätigen, analysieren unsere
                Algorithmen vollautomatisch Ihre Kampagnen. Dazu werden die
                historischen Kampagnendaten Ihres Google Ads Accounts unter
                anderem mit externen Daten wie historischen Wetterdaten
                gematched.
              </p>
            </div>
            <div className="pt-2 lead text-sm-left CooperationStepsSection-content">
              <h6 className="CooperationStepsSection-content-headline">
                3. Reporting erhalten
              </h6>
              <p>
                Innerhalb von 10 Werktagen erhalten Sie Ihre Analyse als
                visualisiertes Reporting. Wie sieht ein solches Reporting aus?{' '}
                <a
                  href={withPrefix(
                    '/customer-files/frogo-ad-analysis-example.xlsx'
                  )}
                  rel="noopener"
                >
                  Hier
                </a>{' '}
                Beispiel-Analyse downloaden.
              </p>
            </div>
            <div className="pt-2 lead text-sm-left CooperationStepsSection-content">
              <h6 className="CooperationStepsSection-content-headline">
                4. Rücksprache mit FROGO AD
              </h6>
              <p>
                Bei Bedarf berät Sie anschließend einer unserer zertifizierten
                Google Ads-Spezialisten. In diesem Zusammenhang können wir
                gemeinsam darüber nachdenken, ob die kostenpflichtigen
                Technologielösungen von FROGO AD zur Optimierung Ihrer Kampagnen
                beitragen. Sollte kein Bedarf bestehen, kennen Sie nun zumindest
                Ihr Optimierungspotential.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CooperationStepsSection;
